import RolesEnum from "common/enums/RolesEnum";
import { DocumentIcon, PersonIcon } from "../../assets/images/icons";
import {
  ALL_MEMBERS,
  ALL_RECENT_ORDERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  NPN_LIST,
  NPN_DETAILS,
  NURSE_LIST,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  STAFF_MEMBERS,
  REPORT_LIST,
  REPORT_DETAILS,
  CANCELED_MEMBERS,
  RETENTION,
  REPORT_DETAILS_BY_MEMBER,
  ALL_TEAMS,
  TEAM_DETAILS,
  CREATE_TEAM,
  REGISTER_MEMBER,
  REQUEST_CONSENTS,
  MEMBER_CHART,
  PROVIDER_LIST,
  MEMBER_CHART_CALENDARING,
  STAFF_DETAILS,
  NURSE_SCHEDULE,
  MY_TODOS,
  COMPLETED_TODOS,
  VISITS,
  NURSES_SCHEDULES
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_CLINICAL_TEAMS,
  SIDEBAR_INACTIVE_CANCELED_MEMBERS,
  SIDEBAR_RETENTION,
  SIDEBAR_CREATE_TEAM,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_NPNS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SUPPORT,
  SIDEBAR_SECTIONS
} from "../RouteSidebar";
import RouteComponentTypeEnum from "common/enums/RouteComponentTypeEnum";

const ADMIN = {
  label: "Admin",
  routes: [
    {
      path: "/",
      components: [STAFF_MEMBERS]
    },
    {
      path: "/create-user",
      components: [
        {
          type: "create-user"
        }
      ]
    },
    CREATE_TEAM,
    ALL_TEAMS({}),
    TEAM_DETAILS,
    STAFF_DETAILS,
    {
      path: "/nurses",
      components: [NURSE_LIST({})]
    },
    {
      path: "/nurses/nurseId/:userId",
      components: [{ type: RouteComponentTypeEnum.MEMBER_DETAILS_REDIRECT }]
    },
    NURSE_SCHEDULE,
    ...NURSES_SCHEDULES,
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    {
      path: "/members",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/awaiting-provider",
      components: [
        AWAITING_PROVIDER({
          tableColumns: [
            { name: "name" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" }
          ]
        })
      ]
    },
    { path: "/inactive-members", components: [INACTIVE_MEMBERS(true)] },

    ...MEMBER_CHART(RolesEnum.ADMIN),
    ...MEMBER_CHART_CALENDARING(),
    {
      path: "/members/nurseId/:nurseId",
      components: [
        MEMBERS_WITH_NO_ACTIVITY({}),
        ASSIGNED_MEMBERS({
          recentOrdersSinceDays: 15
        })
      ]
    },
    {
      path: "/orders",
      components: [
        {
          type: "order-devices"
        },
        ALL_RECENT_ORDERS()
      ]
    },
    {
      path: "/devices/:memberId",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    ORDER_DETAILS(),
    { path: "/register-member", components: [REGISTER_MEMBER] },
    { path: "/request-consents", components: [REQUEST_CONSENTS] },
    {
      path: "/help",
      components: [
        {
          type: "help"
        }
      ]
    },
    {
      path: "/providers",
      components: [
        PROVIDER_LIST({
          filterByAuthenticatedUser: false
        })
      ]
    },
    NPN_LIST({}),
    NPN_DETAILS,
    RETENTION({}),
    REPORT_LIST,
    REPORT_DETAILS,
    CANCELED_MEMBERS,
    REPORT_DETAILS_BY_MEMBER,
    CREATE_TEAM,
    MY_TODOS,
    COMPLETED_TODOS,
    VISITS
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.users,
      items: [
        {
          itemName: "Create User",
          itemLink: "/create-user",
          ItemIcon: DocumentIcon
        },
        SIDEBAR_CREATE_TEAM,
        {
          itemName: "Register Member",
          itemLink: "/register-member",
          ItemIcon: PersonIcon
        },
        {
          itemName: "Request Consents",
          itemLink: "/request-consents",
          ItemIcon: PersonIcon
        },
        SIDEBAR_CLINICAL_TEAMS
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "All Nurses",
          itemLink: "/nurses",
          ItemIcon: PersonIcon
        },
        {
          itemName: "Nurses Schedules",
          itemLink: "/nurses-schedules/schedules",
          ItemIcon: PersonIcon
        },
        SIDEBAR_NPNS
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.providers,
      items: [
        {
          itemName: "All Providers",
          itemLink: "/providers",
          ItemIcon: PersonIcon
        }
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_ALL_MEMBERS, SIDEBAR_INACTIVE_MEMBERS, SIDEBAR_ORDERS]
    },
    {
      sectionName: SIDEBAR_SECTIONS.retention,
      items: [SIDEBAR_RETENTION, SIDEBAR_INACTIVE_CANCELED_MEMBERS]
    },
    SIDEBAR_REPORTS,
    SIDEBAR_SUPPORT
  ]
};

export default ADMIN;
