import { Box } from "@mui/material";
import Tasks from "../../pages/Tasks/Tasks";
import { Flexbox } from "../../styling/NewStyleComponents";
import NewScheduleToday from "../ScheduleToday/NewScheduleToday";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { DateTime } from "luxon";
import { checkIdValid, isFalsy } from "common/helpers/helpers";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import ErrorComponent from "../ErrorComponent";
import { css } from "@emotion/react";
import TaskStatusEnum from "common/enums/TaskStatusEnum";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import { useGetUserWithUsernameQuery } from "common/services/UserService";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import useGetCalendarVisits from "../../hooks/data_loaders/useGetCalendarVisits";
import RouteComponentTypeEnum from "common/enums/RouteComponentTypeEnum";
import Papa from "papaparse";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

function parseCSVData(
  stringData: string,
  callbackFn: Dispatch<SetStateAction<any>>
) {
  Papa.parse(stringData, {
    download: false,
    complete: function (input) {
      const result = input?.data;
      const resultMap = {};
      result.forEach((row) => {
        resultMap[row[0]] = row[1];
      });
      callbackFn(resultMap);
    }
  });
}

const SHOW_TARGET_MINS = true;

function NewScheduleToDoContainer({
  rightComponent,
  nurseId: propsNurseId
}: {
  rightComponent?: RouteComponentTypeEnum;
  nurseId?: string;
}) {
  const [fetchedCSVData, setFetchedCSVData] = useState<any>();

  useEffect(() => {
    if (SHOW_TARGET_MINS) {
      fetch(
        // let's update the data file to include the date for cache busting purposes
        `${process.env.PUBLIC_URL}/data/2024-09-15-${process.env.REACT_APP_STACK_DEPLOYMENT_ENV}patientencounterdata.csv`
      )
        .then((res) => res.text())
        .then((stringData) => {
          parseCSVData(stringData, setFetchedCSVData);
        });
    } else {
      setFetchedCSVData({});
    }
  }, []);
  const { user } = useSelector((state: RootState) => state.auth);

  const { nurseId: paramsNurseId } = useSanitizedParams();

  const nurseId = paramsNurseId || propsNurseId;

  const startdate = DateTime.utc().startOf("day").minus({ days: 14 });
  const enddate = DateTime.utc().endOf("day").plus({ days: 29 });

  const {
    data: carerData,
    isLoading: isLoadingCarerData,
    error: carerError
  } = useGetUserWithUsernameQuery(
    { username: nurseId },
    { skip: isFalsy(nurseId) || !checkIdValid(nurseId) }
  );

  const { data: authenticatedUser } = useGetAuthenticatedUser({
    skip: checkIdValid(nurseId)
  });

  const {
    data: calendarVisitsData,
    isFetching: calendarVisitsIsFetching,
    error: calendarVisitsError,
    refetch: refetchCalendarVisits
  } = useGetCalendarVisits({
    staff_id: nurseId ?? user?.user_id,
    startdate,
    enddate
  });

  const finalCalendarVisitsData = useMemo(() => {
    if (calendarVisitsData?.length && fetchedCSVData) {
      return calendarVisitsData.map((item) => {
        let attendee = item?.attendees?.[0];
        let recommended_encounter_minutes;
        if (fetchedCSVData?.[attendee?.attendee_id]) {
          recommended_encounter_minutes =
            fetchedCSVData?.[attendee?.attendee_id];
        }
        return {
          ...item,
          attendees: [
            {
              ...attendee,
              ...(recommended_encounter_minutes && {
                recommended_encounter_minutes
              })
            }
          ]
        };
      });
    } else {
      return [];
    }
  }, [calendarVisitsData, fetchedCSVData]);

  // const { data: appointmentsData } = useGetStaffScheduleQuery({
  //   staff_id: nurseId ?? user?.user_id,
  //   startdate,
  //   enddate
  // });

  return (
    <Flexbox
      flexDirection="row"
      margin="2.5%"
      width="95%"
      gap="12px"
      css={
        rightComponent === RouteComponentTypeEnum.TASKS
          ? css`
              flex-wrap: nowrap;
              @media (max-width: 1160px) {
                flex-wrap: wrap;
              }
            `
          : css`
              flex-wrap: nowrap;
            `
      }
    >
      <Box
        css={
          // if we show the to-do container, we want to show the calendar on the left side for larger screens
          rightComponent === RouteComponentTypeEnum.TASKS
            ? css`
                flex-basis: 50%;
                @media (max-width: 1160px) {
                  flex-basis: 100%;
                }
              `
            : css`
                flex-basis: 100%;
              `
        }
        minWidth={"586px"}
      >
        {(calendarVisitsIsFetching || isLoadingCarerData) && (
          <LoadingFallback count={15} />
        )}
        {finalCalendarVisitsData &&
          !isLoadingCarerData &&
          !calendarVisitsIsFetching &&
          fetchedCSVData && (
            <NewScheduleToday
              selectedNurseId={nurseId}
              refetchCalendarVisits={refetchCalendarVisits}
              calendarEventsData={finalCalendarVisitsData}
              startdate={startdate}
              enddate={enddate}
              currentUserId={user?.user_id}
              carerTimezone={
                carerData?.user?.timezone ?? authenticatedUser?.user?.timezone
              }
              carerError={carerError}
            />
          )}
        {calendarVisitsError && <ErrorComponent error={calendarVisitsError} />}
      </Box>
      <Box
        css={
          rightComponent === RouteComponentTypeEnum.TASKS
            ? css`
                flex-basis: 50%;
                @media (max-width: 1160px) {
                  flex-basis: 100%;
                }
              `
            : css`
                flex-basis: 0%;
              `
        }
      >
        {rightComponent === RouteComponentTypeEnum.TASKS && (
          <Tasks
            outerContainerMargin="0"
            tasksStatuses={[TaskStatusEnum.TODO, TaskStatusEnum.IN_PROGRESS]}
            componentHeader="To Dos"
            customTasksContainerHeight="508px"
            showWhenNoTasks={true}
            noTasksMessage={
              nurseId
                ? "Nurse has no pending tasks that require imminent action."
                : "You have no pending tasks that require imminent action."
            }
            showResolveButton={false}
            showActionButtons={true}
            tooltipContent="Important and urgent tasks are shown in this module."
            componentHeaderMarginBottom="12px"
            link={
              nurseId
                ? // don't show completed to-dos when viewing another nurse's report
                  null
                : {
                    text: "View Completed",
                    route: "/my-completed-todos"
                  }
            }
          />
        )}
      </Box>
    </Flexbox>
  );
}

export default NewScheduleToDoContainer;
