import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Divider, Typography, Chip } from "@mui/material";
import {
  CallOutlined,
  EditOutlined,
  EditCalendarOutlined
} from "@mui/icons-material";

import {
  ProviderRoles,
  canCallMember,
  canCallTwilioVideo,
  canEditMemberDetails,
  canScheduleNurses,
  canSeeTimer,
  canStartIntake,
  canSubmitEncounters,
  canUpdateNurseAssignment,
  canUpdateProviderAssignment
} from "common/enums/RolesEnum";
import { RootState, useAppDispatch } from "common/redux";

import { CustomTooltip, StatusBadge } from "../../../styling/StyleComponents";
import {
  RemoteIqMobileIconBW,
  RemoteIqMobileIconColor
} from "../../../assets/images/icons";
import CopyPatientLinkToClipboard from "../../../styling/CopyPatientLinkToClipboard";
import CallTwillioPatientButton from "../../../components/Button/CallTwilioPatientButton";
import { callPatientModalSelector } from "../../../helpers/helpers";
import { gray } from "../../../styling/colors";
import { useSelector } from "react-redux";
import StartIntakeButton from "../../PatientData/StartIntakeButton";
import ErrorComponent from "../../../components/ErrorComponent";
import SubmitEncountersForm from "./SubmitEncounterForm";
import CarersMenu from "./CarersMenu";
import { getNameOrUsername } from "common/helpers/helpers";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { DateTime } from "luxon";
import AthenaButton from "../../../components/Button/AthenaChartButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import MemberType from "common/types/MemberType";
import { MEMBERS_PATH } from "../../../routes/RouteComponents";
import { setTimerStart } from "common/redux/webadmin/LayoutSlice";
import StartEncounterButton from "./StartEncounterButton";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import SubmitEncounterContainer from "./SubmitEncounter/SubmitEncounterContainer";
import EnvVars from "common/config/EnvVars";
import MemberHeaderNew from "./MemberHeaderNew";

const MemberDetailsHeaderContainer = styled.div`
  border-radius: 4px;
  padding: 4px;
  border: 1px solid ${gray[300]};
  background: #ffffff;
  display: flex;
  width: inherit;
  justify-content: space-between;
  z-index: 1000;
  align-items: center;
  overflow-x: scroll;
`;

const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PatientLinkContainer = styled.div`
  width: 20px;
  height: 20px;
`;

const StyledRemoteIqBWIcon = styled(RemoteIqMobileIconBW)`
  height: 34px;
  width: 18px;
`;

const StyledRemoteIqColorIcon = styled(RemoteIqMobileIconColor)`
  height: 34px;
  width: 18px;
`;

const StyledRemoteIqIconContainer = styled.div`
  width: 18px;
  height: 34px;
`;

export function MemberHeader({
  patient,
  isPatientError,
  memberId,
  nurseZoomPhone,
  hideButtons,
  hideStartEncounter = false
}: Readonly<{
  patient: MemberType;
  isPatientError: unknown;
  memberId: string | undefined;
  nurseZoomPhone?: string;
  hideButtons?: boolean;
  hideStartEncounter?: boolean;
}>) {
  // FEATURE FLAG: AUG-2024-CARE-FLOWS
  if (EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV === "dev")
    return (
      <MemberHeaderNew
        memberId={memberId}
        nurseZoomPhone={nurseZoomPhone}
        patient={patient}
        isPatientError={isPatientError}
        hideButtons={hideButtons}
        hideStartEncounter={hideStartEncounter}
      />
    );

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { timerStart } = useSelector((state: RootState) => state.layout);
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const isEditProviderAllowed = canUpdateProviderAssignment(currentRole);
  const isEditNurseAllowed = canUpdateNurseAssignment(currentRole);

  const provider = patient?.patient_provider;
  const providerName = getNameOrUsername(provider);

  const nurse = patient?.assigned_nurse;
  const nurseName = getNameOrUsername(nurse);

  const athenaId = patient?.patient?.external_accounts?.athena;

  const doesNotHaveNumber =
    !patient?.patient?.phone && !patient?.patient?.mobile;

  const getMemberTime = () => {
    return DateTime.now().setZone(patient?.patient?.timezone);
  };
  const [memberTime, setMemberTime] = useState(getMemberTime());

  const startTime = useMemo(() => DateTime.now(), []);

  useEffect(() => {
    const interval = setInterval(() => setMemberTime(getMemberTime()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getTime = useCallback(() => {
    const now = DateTime.now();
    const diff = now.diff(timerStart ?? startTime);
    const diffString = diff.toFormat("hh:mm:ss");

    const hour = Math.floor(diff.as("hours"));
    const showHourString = hour >= 1;
    return `${showHourString ? diffString : diffString.slice(3)}`;
  }, [timerStart]);

  const [timer, setTimer] = useState(getTime());

  useEffect(() => {
    let interval;
    if (canSeeTimer(currentRole)) {
      interval = setInterval(() => setTimer(getTime()), 1000);
    }
    return () => {
      if (canSeeTimer(currentRole) && interval) {
        clearInterval(interval);
      }
    };
  }, [memberId, currentRole]);

  useEffect(() => {
    if (!timerStart && canSeeTimer(currentRole)) {
      dispatch(setTimerStart(startTime));
    }
  }, [timerStart, startTime, currentRole]);

  return (
    <>
      {patient && (
        <MemberDetailsHeaderContainer>
          <Flexbox
            flexBasis="65%"
            sx={{ flexGrow: "1" }}
            height="max-content"
            alignItems="center"
          >
            <Flexbox
              alignItems="center"
              gap="24px"
              padding="5px 10px"
              justifyContent="space-between"
              width="100%"
              height="max-content"
            >
              <Flexbox
                flexDirection="column"
                flexWrap="wrap"
                height="max-content"
                minWidth="max-content"
              >
                <Flexbox gap="4px">
                  <Typography
                    data-testid="MemberDetailsHeader-patientName"
                    variant="h3"
                    marginTop={"2%"}
                    // set the next 2 lines to prevent long names from taking up too much horizontal space
                    maxWidth="200px"
                    whiteSpace="normal"
                  >
                    {patient && getNameOrUsername(patient.patient)}
                  </Typography>
                  <Flexbox gap="10px" alignItems="center">
                    <PatientLinkContainer>
                      <CopyPatientLinkToClipboard
                        memberId={memberId}
                        customLink={MEMBERS_PATH}
                      />
                    </PatientLinkContainer>

                    {athenaId !== undefined && (
                      <AthenaButton athenaId={athenaId} />
                    )}

                    {patient?.patient_access_summary?.has_logged_in ? (
                      <StyledRemoteIqIconContainer>
                        <CustomTooltip
                          title="Member has logged into CopilotIQ mobile app"
                          placement="top"
                        >
                          <StyledRemoteIqColorIcon />
                        </CustomTooltip>
                      </StyledRemoteIqIconContainer>
                    ) : (
                      <StyledRemoteIqIconContainer>
                        <CustomTooltip
                          title="Member has not logged into CopilotIQ mobile app"
                          placement="top"
                        >
                          <StyledRemoteIqBWIcon />
                        </CustomTooltip>
                      </StyledRemoteIqIconContainer>
                    )}

                    <StatusBadge
                      status={patient?.patient.status}
                      statusReason={patient?.patient.status_reason}
                    />
                  </Flexbox>
                </Flexbox>
                <Flexbox alignItems="center" gap="10px" marginTop="5px">
                  <Flexbox gap="8px">
                    {patient?.patient.birthdate && (
                      <Typography variant="body2" color="text.secondary">
                        Born {patient?.patient.birthdate}
                      </Typography>
                    )}
                    <Typography variant="body2" color="text.secondary">
                      Member Time: {memberTime.toFormat("h:mm a ZZZZ")}
                    </Typography>
                  </Flexbox>
                  {!hideButtons && canSeeTimer(currentRole) && (
                    <Flexbox>
                      <Chip
                        sx={{
                          minWidth: "100px",
                          backgroundColor: "primary.main",
                          color: "white"
                        }}
                        label={
                          <Typography variant="body2">
                            Timer: {timer}
                          </Typography>
                        }
                      />
                    </Flexbox>
                  )}
                </Flexbox>
              </Flexbox>
              <Flexbox
                gap="8px"
                minHeight="48px"
                minWidth="141px"
                flexWrap="wrap"
                height="fit-content"
              >
                {!hideButtons && (
                  <>
                    {canCallMember(currentRole) && (
                      <>
                        {doesNotHaveNumber ? (
                          <CustomTooltip
                            backgroundColor="#ffffff"
                            placement="bottom"
                            title={
                              <Column>
                                <Typography color={"red"}>
                                  No associated phone number.
                                </Typography>
                              </Column>
                            }
                          >
                            <CallOutlined
                              color={"disabled"}
                              sx={{
                                border: 1,
                                borderColor: "lightgray",
                                borderRadius: "4px",
                                padding: 1.5
                              }}
                            />
                          </CustomTooltip>
                        ) : (
                          <CustomTooltip
                            backgroundColor="#ffffff"
                            placement="bottom"
                            title={
                              <Typography variant="body1" color="text.primary">
                                Call
                              </Typography>
                            }
                          >
                            <Button
                              sx={{ fontSize: "20px" }}
                              variant="outlined"
                              onClick={() =>
                                callPatientModalSelector(
                                  dispatch,
                                  patient?.patient
                                )
                              }
                            >
                              <CallOutlined
                                fontSize="inherit"
                                sx={{ cursor: "pointer" }}
                                color={"primary"}
                              />
                            </Button>
                          </CustomTooltip>
                        )}
                      </>
                    )}
                    {canCallTwilioVideo(currentRole) && (
                      <CallTwillioPatientButton patient={patient} />
                    )}
                    {canScheduleNurses(currentRole) && (
                      // change this back to canCreateMemberAppointment when we allow scheduling provider appointments, make sure to check against the google doc
                      // {canCreateMemberAppointment(currentRole) && (
                      <CustomTooltip
                        backgroundColor="#ffffff"
                        placement="bottom"
                        title={
                          <Typography variant="body1" color="text.primary">
                            New Appointment
                          </Typography>
                        }
                      >
                        <Button
                          sx={{ fontSize: "20px" }}
                          variant="outlined"
                          onClick={() => {
                            if (
                              patient?.patient.status ===
                                MemberStatusEnum.CANCELED ||
                              patient?.patient.status ===
                                MemberStatusEnum.AUTO_CANCELED
                            ) {
                              const id = `cancel-warning-${patient?.patient?.patient_id}`;
                              Alert_show({
                                dispatch,
                                id,
                                title: "Error",
                                content:
                                  "Patient is canceled. Are you sure you want to schedule an appointment?",
                                type: "error",
                                size: "small",
                                buttons: [
                                  {
                                    text: "Yes",
                                    style: "default",
                                    onPress: () => {
                                      Alert_close({ dispatch, id });
                                      if (
                                        pathname ===
                                        `/members/memberId/${patient.patient.patient_id}/new-appointment`
                                      ) {
                                        // do nothing if we are already on the new appointment page
                                      } else {
                                        navigate(
                                          `/members/memberId/${patient.patient.patient_id}/new-appointment`
                                        );
                                      }
                                    }
                                  },
                                  {
                                    text: "No",
                                    style: "cancel",
                                    onPress: () => {
                                      Alert_close({ dispatch, id });
                                    }
                                  }
                                ]
                              });
                            } else if (
                              pathname ===
                              `/members/memberId/${patient.patient.patient_id}/new-appointment`
                            ) {
                              // do nothing if we are already on the new appointment page
                            } else {
                              navigate(
                                `/members/memberId/${patient.patient.patient_id}/new-appointment`
                              );
                            }
                          }}
                        >
                          <EditCalendarOutlined
                            fontSize="inherit"
                            sx={{ cursor: "pointer" }}
                            color={"primary"}
                          />
                        </Button>
                      </CustomTooltip>
                    )}
                    {canEditMemberDetails && (
                      <CustomTooltip
                        backgroundColor="#ffffff"
                        placement="bottom"
                        title={
                          <Typography variant="body1" color="text.primary">
                            Edit Member Details
                          </Typography>
                        }
                      >
                        <Button
                          sx={{ fontSize: "20px" }}
                          variant="outlined"
                          color="primary"
                          id="basic-button"
                          onClick={() =>
                            navigate(
                              `/members/memberId/${patient.patient.patient_id}/profile`
                            )
                          }
                        >
                          <EditOutlined
                            fontSize="inherit"
                            sx={{ cursor: "pointer" }}
                            color={"primary"}
                          />
                        </Button>
                      </CustomTooltip>
                    )}
                  </>
                )}
                <CarersMenu
                  patient={patient}
                  isEditProviderAllowed={isEditProviderAllowed}
                  isEditNurseAllowed={isEditNurseAllowed}
                  providerName={providerName}
                  nurseName={nurseName}
                  nurseZoomPhone={nurseZoomPhone}
                />
                <StartEncounterButton patient={patient} />
                {canStartIntake(currentRole) && (
                  <StartIntakeButton
                    key={`startintake_${athenaId}`}
                    athena_id={athenaId}
                    member_id={memberId}
                  />
                )}
              </Flexbox>
            </Flexbox>
          </Flexbox>
          {canSubmitEncounters(currentRole) && (
            <Flexbox height="auto">
              <Divider orientation="vertical" flexItem />
              <Flexbox flexBasis="max-content">
                {
                  // only show this view for Intake Nurses and NPs
                  ProviderRoles.includes(currentRole) ? (
                    <SubmitEncounterContainer
                      memberId={memberId}
                      patient={patient}
                    />
                  ) : (
                    <SubmitEncountersForm memberId={memberId} />
                  )
                }
              </Flexbox>
            </Flexbox>
          )}
        </MemberDetailsHeaderContainer>
      )}
      {isPatientError && (
        <ErrorComponent
          error={
            // ENG-3654
            "RemoteIQ member ID does not exist, please check the link is correct and does not contain any extra characters"
          }
        />
      )}
    </>
  );
}
